import {

  Card,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import {
  AdapterDateFns
} from '@mui/x-date-pickers/AdapterDateFns';
import {
  LocalizationProvider
} from '@mui/x-date-pickers/LocalizationProvider';
import AppReferral from 'app/screens/shared/components/@dashboard/general/app/AppReferral';
import moment from 'moment';
import * as React from 'react';
import {
  useEffect,
  useState
} from 'react';
import {
  CustomerTableToolbar
} from '~components/list';
import Loader from '~components/loader/Loader';
import {
  getLastFiveDigitsFromObjectId
} from '~helper/index';
import {
  useReferralQuery
} from './services/referralProgramApi';

const SERVICE_OPTIONS = [
  'all',
  'pending',
  'Instock',
  'Out of stock',
];

function Referral() {

  let totalEarning = 0

  const [filterName, setFilterName] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterService, setFilterService] = useState('all');
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);

  const {
    data = [],
    isLoading,
  } = useReferralQuery({})

  if (data.length > 0)
    totalEarning
      = data.reduce(
        (
          prev: number,
          obj: any
        ) =>
          prev + obj.earning,
        0
      )

  const isFiltered =
    filterStatus !== 'all' ||
    filterName !== '' ||
    filterService !== 'all' ||
    (!!filterStartDate && !!filterEndDate);

  const modifiedData = data?.map(({
    _id,
    referralUser,
    earning,
    referredUser: { createdAt }
  }: any) => ({
    id: getLastFiveDigitsFromObjectId(_id),
    no: getLastFiveDigitsFromObjectId(referralUser),
    date: moment(createdAt).format('DD MMM YYYY'),
    price: `$${earning}`,
  }))

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {

    setFilterName(event.target.value);
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {

    setFilterService(event.target.value);
  };
  const handleResetFilter = () => {
    setFilterName('');
    setFilterStatus('all');
    setFilterService('all');
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  return (

    <Grid spacing={3}>
      <Stack
        direction="row"
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Grid item xs={12} md={7}>
          <Stack direction="row" sx={{ display: "flex", pt: 2 }}>
            <Typography
              variant="h2"
              component="h2"
              paragraph
              className="d-flex"  >
              Referral
            </Typography>
            <Typography
              style={{
                color: "#EF7484"
              }}
              variant="h2"
              component="h2"
              paragraph
              className="px-3"  >
              List
            </Typography>

          </Stack>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            dir="ltr"
            className="py-3 px-4 mt-2"
            style={{
              backgroundColor: "#FAD5DA",
              paddingRight: "150px"
            }}
          >
            <Grid item xs={12} md={4}>
              <Typography
                style={{
                  color: "#D26F7C",
                  fontWeight: "normal",
                  fontSize: "13px"
                }}
                paragraph
                className=""
              >
                Your total earning
              </Typography>
            </Grid>
            <Grid>
              <Typography style={{ color: "#D26F7C" }}
                variant="h5"
                component="h5"
                paragraph
              >
                ${totalEarning?.toFixed?.(2)}
              </Typography>
            </Grid>
          </Card>
        </Grid>
      </Stack>

      <Grid xs={12} style={{ padding: "14px" }}>
        <Card>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CustomerTableToolbar
              isFiltered={isFiltered}
              filterName={filterName}
              filterService={filterService}
              filterEndDate={filterEndDate}
              onFilterName={handleFilterName}
              optionsService={SERVICE_OPTIONS}
              onResetFilter={handleResetFilter}
              filterStartDate={filterStartDate}
              onFilterService={handleFilterService}
              onFilterStartDate={(newValue: any) => {
                setFilterStartDate(newValue);
              }}
              onFilterEndDate={(newValue: any) => {
                setFilterEndDate(newValue);
              }}
            />
          </LocalizationProvider>
          {!isLoading ?
            modifiedData?.length > 0 ?
              <AppReferral
                hideViewAll={true}
                tableData={modifiedData?.length > 0 ? modifiedData : []}
                tableLabels={[
                  { id: 'id', label: '#' },
                  { id: 'No', label: 'Referral No' },
                  { id: 'date', label: 'Date' },
                  { id: 'price', label: 'Price' },
                ]}
              />
              :
              <div style={{ height: '100px', padding: '16px' }}>
                <p>No record found</p>
              </div>
            :
            <Loader />
          }
        </Card>

      </Grid>
    </Grid>

  );
}

export default Referral