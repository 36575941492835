import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useResponsive from '~hooks/useResponsive';
import { useReferralQuery } from './services/referralProgramApi';
import { AppUrl } from '~config/baseUrl';



export default function ReferralLink() {
  let totalEarning = 0;

  const {
    palette: { primary, success },
  }: any = useTheme();

  const [copying, setCopying] = useState(false);

  const { user } = useSelector((state: any) => state.auth);

  const isMobile = useResponsive('down', 'sm');

  const { data = [], isLoading } = useReferralQuery({});

  if (data.length > 0)
    totalEarning = data.reduce(
      (prev: number, obj: any) => prev + obj.earning,
      0
    );

  const refferalLink = `${AppUrl}/?id=${user?.userDto?._id}`;

  const handleCopyText = () => {
    setCopying(true);

    navigator.clipboard.writeText(refferalLink);

    setTimeout(() => setCopying(false), 1000);
  };

  return (
    <Grid spacing={3}>
      <Stack
        direction="row"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={12} md={8}>
          <Stack direction="row" sx={{ display: 'flex', pt: 2 }}>
            <Typography
              variant="h2"
              component="h2"
              paragraph
              className="d-flex"
            >
              Referral
            </Typography>
            <Typography
              style={{ color: '#EF7484' }}
              variant="h2"
              component="h2"
              paragraph
              className="px-3"
            >
              List
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} md={3} style={{ padding: '14px' }}>
          <Card
            dir="ltr"
            className="py-3 px-4 mt-2"
            style={{
              backgroundColor: '#FAD5DA',
              paddingRight: '150px',
            }}
          >
            <Grid>
              <Typography
                style={{
                  color: '#D26F7C',
                  fontWeight: 'normal',
                  fontSize: '13px',
                }}
                paragraph
                className=""
              >
                Your total earning
              </Typography>
            </Grid>
            <Grid>
              <Typography
                style={{ color: '#D26F7C' }}
                variant="h5"
                component="h5"
                paragraph
              >
                ${totalEarning?.toFixed?.(2)}
              </Typography>
            </Grid>
          </Card>
        </Grid>
      </Stack>

      <Grid xs={12}>
        <Stack direction="row" style={{ color: '#EF7484' }}>
          <Typography
            sx={{
              fontSize: {
                md: '48px',
                xs: '32px',
              },
            }}
            mt={1}
            fontWeight="900"
            color={primary.dark}
          >
            Refer and give $5,
          </Typography>
        </Stack>
        {/* <Stack direction="row" sx={{ mb: 0 }}  > */}
        <Typography
          sx={{
            fontSize: {
              md: '32px',
              xs: '20px',
            },
          }}
          fontWeight="700"
        >
          they purchase and you get $5
        </Typography>
        {/* </Stack> */}
        <Stack direction="row">
          <Typography mt={1} fontSize="16px" fontWeight="500">
            Give your friend a head start with $5 in credit!
            <br />
            it's easy: just share you link with them and we'll give
            both of you $5 when they purchase their first song from
            us.
          </Typography>
        </Stack>
        <Box
          display="flex"
          gap={1}
          my={5}
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
          }}
        >
          <TextField
            disabled
            id="text-field"
            name="firstName"
            value={refferalLink}
            fullWidth
            InputProps={{
              endAdornment: copying ? (
                <DoneAllIcon
                  sx={{
                    color: success.main,
                  }}
                />
              ) : (
                <InputAdornment
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={handleCopyText}
                  position="end"
                >
                  Copy
                </InputAdornment>
              ),
            }}
          />
          <Button
            size="large"
            sx={{
              width: isMobile ? '100%' : '84px',
            }}
            // onClick={() =>
            //   setShareModal(true)
            // }
            variant="contained"
          >
            Share
          </Button>
        </Box>
      </Grid>
      <Stack
        direction="row"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Grid container spacing={5} style={{ padding: '14px' }}>
          <Grid item xs={12} md={4}>
            <Card
              dir="ltr"
              className="py-3 px-4 mt-2"
              style={{
                backgroundColor: '#FFFF',
                height: '164px',
              }}
            >
              <Grid>
                <Typography
                  style={{
                    color: '#A3B6C5',
                    fontWeight: 'bold',
                  }}
                  variant="h4"
                  component="h4"
                  paragraph
                >
                  Share your unique link
                </Typography>
              </Grid>
              <Grid>
                <Typography paragraph>
                  Share your unique referral link with your
                  family, friends and the rest of the world.
                </Typography>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              dir="ltr"
              className="py-3 px-4 mt-2"
              style={{ backgroundColor: '#FFFF' }}
            >
              <Grid>
                <Typography
                  style={{
                    color: '#EF7484',
                    fontWeight: 'bold',
                  }}
                  variant="h4"
                  component="h4"
                  paragraph
                >
                  They get $5
                </Typography>
              </Grid>
              <Grid>
                <Typography paragraph>
                  When an account is created using your
                  referral link, they will immediately get $5
                  credit to purchase a song.
                </Typography>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              dir="ltr"
              className="py-3 px-4 mt-2"
              style={{ backgroundColor: '#FFFF' }}
            >
              <Grid>
                <Typography
                  style={{
                    color: '#F9B294  ',
                    fontWeight: 'bold',
                  }}
                  variant="h4"
                  component="h4"
                  paragraph
                >
                  You earn $5
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  paragraph
                >
                  When the person you referred completes a purchase of his first song, you will get $5 credit to use in Songoven.
                </Typography>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  );
}
