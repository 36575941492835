import { useState, useEffect } from 'react';
import { sentenceCase } from 'change-case';
import { Icon } from '@iconify/react';
// @mui
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  CardProps,
  CardHeader,
  IconButton,
  TableContainer, Stack, Typography, Avatar, LinearProgress, Grid
} from '@mui/material';
// utils
import { fCurrency } from 'app/config/utils/formatNumber';
// components
import Label from 'app/screens/shared/components/label';
import Iconify from 'app/screens/shared/components/iconify';
import Scrollbar from 'app/screens/shared/components/scrollbar';
import MenuPopover from 'app/screens/shared/components/menu-popover';
import { TableHeadCustom } from 'app/screens/shared/components/table';

// ----------------------------------------------------------------------

type RowProps = {
  id: string
  no: string;
  date: string;
  price: string;


};

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  tableData: RowProps[];
  hideViewAll?: boolean;
  tableLabels: any;
}

export default function AppReferral({
  title,
  subheader,
  tableData,
  tableLabels,
  hideViewAll,
  ...other
}: Props) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />

      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 720 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData.map((row) => (
                <AppReferralRow key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
      {!hideViewAll &&
        <>
          <Divider />
          <Box sx={{ p: 2, textAlign: 'right' }}>
            <Button
              size="small"
              color="inherit"
              endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
            >
              View All
            </Button>
          </Box>
        </>
      }
    </Card>
  );
}

// ----------------------------------------------------------------------

type AppReferralProps = {
  row: RowProps;
};



function AppReferralRow({ row }: AppReferralProps) {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [progress, setProgress] = useState(0);
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === 100) {
  //         return 0;
  //       }
  //       const diff = Math.random() * 10;
  //       return Math.min(oldProgress + diff, 100);
  //     });
  //   }, 500);

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleDownload = () => {
    handleClosePopover();
    console.log('DOWNLOAD', row.id);
  };

  const handlePrint = () => {
    handleClosePopover();
    console.log('PRINT', row.id);
  };

  const handleShare = () => {
    handleClosePopover();
    console.log('SHARE', row.id);
  };

  const handleDelete = () => {
    handleClosePopover();
    console.log('DELETE', row.id);
  };



  return (
    <>
      <TableRow>


        <TableCell>{`${row.id}`}</TableCell>
        <TableCell>{`${row.no}`}</TableCell>
        <TableCell>{`${row.date}`}</TableCell>
        <TableCell>{`${row.price}`}</TableCell>

      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem onClick={handleDownload}>
          <Iconify icon="eva:download-fill" />
          Download
        </MenuItem>

        <MenuItem onClick={handlePrint}>
          <Iconify icon="eva:printer-fill" />
          Print
        </MenuItem>

        <MenuItem onClick={handleShare}>
          <Iconify icon="eva:share-fill" />
          Share
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>
      </MenuPopover>
    </>
  );
}
