import { api } from "app/services"

export const sharedApi
    = api.injectEndpoints({
        endpoints: (builder) => ({

            referral: builder.query({
                query: () => ({
                    url: `/api/referral`
                }),
            }),
            balance: builder.query({
                query: () => ({
                    url: `/api/balance`
                }),
            }),

        }),
    })

export const {
    useBalanceQuery,
    useReferralQuery,
} = sharedApi