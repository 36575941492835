import Referral from "~screens/referrals-program/refferral";
import ReferralLink from "~screens/referrals-program/refferrallink";

const referralsProgramTabs = [
    {
        value: 'all',
        label: 'Referrals',
        color: 'info',
        component: <Referral />
    },
    {
        value: 'paid',
        label: 'Referrals Link',
        color: 'success',
        component: <ReferralLink />
    },
] as const;


export const listOfReasons = [
    { value: "Schedule Conflicts", text: "Schedule Conflicts: I am currently overbooked or unavailable during the requested timeframe." },
    { value: "Budget Concerns", text: "Budget Concerns: The offered compensation does not meet my financial requirements." },
    { value: "Creative Differences", text: "Creative Differences: The project's style or theme does not align with my artistic direction." },
    { value: "Lack Of Information", text: "Lack of Information: There is insufficient detail about the project to make an informed decision." },
    { value: "Rights And Usage", text: "Rights and Usage: Concerns about copyright, distribution rights, or usage of the final work." },
    { value: "Project Complexity", text: "Project Complexity: The project is too complex or demanding given current resources and time constraints." },
    { value: "Personal Reasons", text: "Personal Reasons: I have personal commitments or circumstances that prevent me from taking on new work." },
    { value: "Quality Standards", text: "Quality Standards: Concerns about maintaining quality standards within the project's constraints." },
    { value: "Collaboration Issues", text: "Collaboration Issues: Previous experiences or reviews suggest potential collaboration challenges." },
    { value: "Other", text: "Other: For reasons not listed above." }
];


export {
    referralsProgramTabs
}